exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-progresify-tsx": () => import("./../../../src/pages/about-progresify.tsx" /* webpackChunkName: "component---src-pages-about-progresify-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-corehrx-tsx": () => import("./../../../src/pages/products/corehrx.tsx" /* webpackChunkName: "component---src-pages-products-corehrx-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-solutions-organisation-tsx": () => import("./../../../src/pages/solutions/organisation.tsx" /* webpackChunkName: "component---src-pages-solutions-organisation-tsx" */),
  "component---src-pages-webdemo-tsx": () => import("./../../../src/pages/webdemo.tsx" /* webpackChunkName: "component---src-pages-webdemo-tsx" */)
}

